.teaser {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    overflow: hidden;
}

.Content {
    color: #f0f0f0;
    cursor: pointer;
}

.clickText {
    transition: all 5s ease-in;

}

.cake {
    transition: font-size 5s ease-in;
    
}